import React, { useRef, useEffect } from 'react';
import '../App.css';
import Marquee from "react-fast-marquee";
import { LandingScreen, ShopAndEarn, BitcoinChart, Rewards,  Customers, Tweet, FAQ, Media, Brands } from '../components';



var data = [
   /*  {
        twitterImg: require("../images/twitter/shine.png"),
        twitterImgMob: require("../images/twitter/shine2.png"),
        imgmarginleft: 20,
        link: "https://twitter.com/shineparamel/status/1519556282130509826?s=20&t=b5JD2tVh_lIJ_Gdjur0ceA"
    }, */
    /* {
        twitterImg: require("../images/twitter/CA.png"),
        twitterImgMob: require("../images/twitter/CA2.png"),
        link: "https://twitter.com/ca_nigam/status/1427673107033038853?t=-Q8mQm0oaDxcrbWm8FkB3Q&s=19"
    }, */
   /*  {
        twitterImg: require("../images/twitter/naimish.png"),
        twitterImgMob: require("../images/twitter/naimish2.png"),
        link: "https://twitter.com/ThatNaimish/status/1423899045311881220"
    }, */
    {
        twitterImg: require("../images/twitter/kashif.png"),
        twitterImgMob: require("../images/twitter/kashif2.png"),
        link: "https://twitter.com/simplykashif/status/1423900294363062274"
    },
    /* {
        twitterImg: require("../images/twitter/ashish.png"),
        link: "https://twitter.com/AshishAjayWagh1/status/1519602294370709504?s=20&t=b5JD2tVh_lIJ_Gdjur0ceA"
    }, */
    {
        twitterImg: require("../images/twitter/pachpu.png"),
        twitterImgMob: require("../images/twitter/pachpu2.png"),
        link: "https://twitter.com/THE5thP1/status/1447824791277166593"
    },
    {
        twitterImg: require("../images/twitter/akki.png"),
        twitterImgMob: require("../images/twitter/akki2.png"),
        link: "https://twitter.com/Akki_Bajaj92/status/1426961169638514692"
    },
    /*  {
         twitterImg: require("../images/twitter/sir.png"),
         link: "https://twitter.com/OfficeOfBitcoin/status/1528672308365123586"
     }, */
    /* {
        twitterImg: require("../images/twitter/jayesh.png"),
        twitterImgMob: require("../images/twitter/jayesh2.png"),
        link: "https://twitter.com/imjaz_13/status/1471318757003841537?t=KmstkmAkbui0XjoDMm3bUQ&s=19"
    }, */
    {
        twitterImg: require("../images/twitter/syed.png"),
        twitterImgMob: require("../images/twitter/syed2.png"),
        link: "https://twitter.com/kaif_ur/status/1471005139813081088?t=h64axI5HFzsN7eVQab5kTA&s=19"
    },
    /* {
        twitterImg: require("../images/twitter/omegus.png"),
        link: "https://twitter.com/omegusfinance/status/1471038800746872836?t=1s5DeF7JdcQ_AWA8ZOQHEA&s=19"
    }, */


    {
        twitterImg: require("../images/twitter/abhishek.png"),
        twitterImgMob: require("../images/twitter/abhishek2.png"),
        link: "https://twitter.com/MetaAbhishek/status/1485920618185129985?t=QwbEtfMIciMAt5H8Y1vLGg&s=19"
    },

    /* {
        twitterImg: require("../images/twitter/ebbey.png"),
        twitterImgMob: require("../images/twitter/ebbey2.png"),
        link: "https://twitter.com/ebbey_thomas/status/1471004249567875072"
    } */

]

const HomePage = () => {
    // const [display, setDisplay] = useState(false)
    // const displaySpinButton = () => setDisplay(true)
    const scrollToDiv = (ref) => window.scrollTo(0, ref.current.offsetTop);
    const scrollFromBitcoinChart = useRef();
    const scrollToSpinWheel = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handelScrollSection = () => {
        scrollToDiv(scrollToSpinWheel)
    }

    return (
        <div >
            <LandingScreen />
                {/* <Media/> */}
                <ShopAndEarn />
                <Brands />
            {/* <BitcoinChart reference={scrollFromBitcoinChart} click={handelScrollSection} /> */}
            <Rewards />
            {/* {
                display ? < SpinWheel2 /> : < SpinWheel reference={scrollToSpinWheel}
                />} */}
            <Customers />
            <Testimonials data={data} />
            <FAQ />
        </div>
    );
}

const Testimonials = ({ data }) => {

    return (
        <div>
            <div className='content-wrapper'>
                <Marquee speed={40} pauseOnHover='true' gradientColor='none'>
                    {
                        testimonialData(data)
                    }
                </Marquee>
            </div>
            <div className='content-wrapperTow '>
                {
                    testimonialData(data)
                }
            </div>
        </div>
    )
}

const testimonialData = (data) => {
    return data.map((obj, index) => {
        return <Tweet coustImgData={obj} key={index} />
    })
}

export default HomePage;