import React, { useState } from "react";
import './style.css';
import { NavLink } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import { HiMenu } from 'react-icons/hi';
import { RiCloseLine } from 'react-icons/ri';
import { Images, Href, Icons } from '../../Constants';

const socialMediaButtonsData = [
    {
        LINKS: Href.GOSATS_WHATSAPP,
        ICONS: Icons.BsWhatsapp,
    },
    {
        LINKS: Href.GOSATS_TELEGRAM,
        ICONS: Icons.FaTelegramPlane,
    },
    {
        LINKS: Href.GOSATS_TWITTER,
        ICONS: Icons.FaTwitter,
    }
]

const Headder = () => {

    const [active, setActive] = useState(false);
    const [activeLink, setActiveLink] = useState("home");

    const showMenu = () => {
        setActive(!active)
    }

    return (
        <div className="header-container" style={{width: '100%'}}>
            <div className="container col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between align-items-center">
                <div className="menu-icon">
                    <NavLink to='/'>
                        <img src={Images.logo} className={activeLink === 'home' ? 'col-md-6 brand-logo-web' : 'col-md-6 brand-logo-web'} alt="Logo" onClick={() => { setActive(false); setActiveLink('home');}} />
                    </NavLink>
                    <button className='qr-button1'
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = Href.GOSATS_ONELINK;
                        }}>
                        <img src={Images.qr} alt="" className='qr-image' />
                        Download the app
                    </button>
                    <HiMenu className="menu" onClick={showMenu} />
                </div>

                <nav className={active ? 'slider active' : 'slider'}>
                    <ul style={{listStyleType: 'none'}}>
                        <div className="closed">
                            <RiCloseLine className="close" onClick={showMenu} style={{ color: '#FFFFFF' }} />
                        </div>

                        <div className="divNav">
                            <li>
                                <NavLink to='/'  onClick={() => { setActive(false); setActiveLink('home');}}>
                                    <img src={Images.logo} className={activeLink === 'home' ? 'col-md-6 brand-logo' : 'col-md-6 brand-logo'} alt="Logo" />
                                </NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <NavLink to='/' onClick={() => { setActive(false); setActiveLink('home') }} className={activeLink === 'home' ? 'active-link' : 'footer-links-about'}>Home</NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <NavLink to='/card' onClick={() => { setActive(false); setActiveLink('card') }} className={activeLink === 'card' ? 'active-link' : 'footer-links-card'}>Card</NavLink>
                            </li>
                            <div className='navBarDivider'></div>
                            {/* <li>
                            <a className='footer-links-nav col-lg-3 col-xl-4' href={Href.GOSATS_BLOG} >Blog</a>
                            </li>
                            <div className='navBarDivider'></div> */}
                            <li>
                                <a className='footer-links-careers me-4' href={Href.GOSATS_CAREERS}>Careers <span className="careerHiring">We're Hiring</span></a>
                            </li>
                            <div className='navBarDivider'></div>
                            <li>
                                <div className="navButtonDoenload">
                                    <button className='qr-button1'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = Href.GOSATS_ONELINK;
                                        }}>
                                        Download The App
                                    </button>
                                </div>
                                <button className='qr-button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = Href.GOSATS_ONELINK;
                                    }}>
                                    <img src={Images.buttonQR} alt="" className='qr-image' />
                                    Download The App
                                </button>
                                <div className=' qr-container'>
                                    <p>Download The App</p>
                                    <img src={Images.qr} className='qr-img' alt="QR Code" />
                                    <div className='qrdivider'></div>
                                    <div className="qrExcentation" onClick={() => window.location.href = Href.GOSATS_CHROME_EXTENCTION}>Install Chrome Extension <span className="qrArrow"><FaChevronLeft className="qrfa" /></span></div>
                                </div>
                                <li className="hamburgerSocialMedia">
                                    <HamburgerCommunity data={socialMediaButtonsData} />
                                </li>
                            </li>
                        </div>
                    </ul>
                </nav>
            </div>
        </div>

        /*  <div className='col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-between  headder-container'>
             <div className="navBar">
                 <NavLink to='/'>
                     <img src={Images.logo} className=' col-md-6 brand-logo' alt="Logo" />
                 </NavLink>
                 <div className="navButtonDoenload">
                     <button className='qr-button'
                         onClick={(e) => {
                             e.preventDefault();
                             window.location.href = Href.GOSATS_ONELINK;
                         }}>
                         Download the app
                     </button>
                 </div>
                 <div style={{ color: 'white' }} className='menuIcon' onClick={handelclick}>
                     {
                         clicked ? <RiCloseLine /> : <FiMenu />
                     }
                 </div>
             </div>
 
             <ul className={clicked ? 'navButtons' :  "navButtons active"}>
                 <li className="mobLogo">
                     <NavLink to='/'>
                         <img src={Images.logo} className=' col-md-6 brand-logo2' alt="Logo" />
                     </NavLink>
                 </li>
                 <div className='mt-2 navDivider'></div>
                 <li>
                     <NavLink to='/about' className='footer-links-about me-4'>About</NavLink>
                 </li>
                 <div className='navDivider'></div>
                 <li className="navBlog">
                     <a className='footer-links col-lg-3 col-xl-4' href={Href.GOSATS_BLOG} >Blog</a>
                 </li>
                 <div className='navDivider'></div>
                 <li>
                     <a className='footer-links-careers me-4' href={Href.GOSATS_CAREERS}>Careers <span className="careerHiring">We're Hiring</span></a>
                 </li>
                 <div className='navDivider'></div>
                 <li>
                     <button className='qr-button'
                         onClick={(e) => {
                             e.preventDefault();
                             window.location.href = Href.GOSATS_ONELINK;
                         }}>
                         <img src={Images.qr} alt="" className='qr-image' />
                         Download the app
                     </button>
                     <div className='qr-container'>
                         <p>Download the App</p>
                         <img src={Images.qr} className='qr-img' alt="QR Code" />
                         <div className='qrnavDivider'></div>
                         <div className="qrExcentation" onClick={() => window.location.href = Href.GOSATS_CHROME_EXTENCTION}>Install Chrome Extension <span className="qrArrow"><FaChevronLeft className="qrfa" /></span></div>
                     </div>
                 </li>
                 <li className="hamburgerSocialMedia">
                     <HamburgerCommunity data={socialMediaButtonsData} />
                 </li>
             </ul>
         </div> */
    );
}

const HamburgerCommunity = ({ data }) => {
    return (
        <div className='navSocialmedia d-flex flex-wrap'>
            {
                data.map((data, index) => (
                    <div className='navbaricon' key={index}>
                        <a href={data.LINKS} className='nav-Social-media'>
                            <data.ICONS color='#A6A7A8' className='nav-icon' />
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

export default Headder;