import React from 'react';
import './style.css';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { Strings, Images, Href, Icons } from '../../Constants';


const footerNavData = [

   /*  {
        TITLE: 'Blog',
        LINKS: Href.GOSATS_BLOG
    }, */
    {
        TITLE: 'Careers',
        LINKS: Href.GOSATS_CAREERS
    },
    {
        TITLE: 'Legal',
        LINKS: Href.GOSATS_LEGAL
    },
    {
        TITLE: 'Self Care Portal',
        LINKS: Href.GOSATS_SELF_CARE_PORTAL
    }
]

const footerDownloadButtonsData = [
    {
        LINKS: Href.DOWNLOAD_BUTTON_APPSTORE,
        IMAGE: Images.appstore,
        CLASS_NAME: 'footer-appStoreLogo',
        ALT_NAME: 'App Store',
        GRID: 'col-xl-3'
    },
    {
        LINKS: Href.DOWNLOAD_BUTTON_PLAYSTORE,
        IMAGE: Images.playstore,
        CLASS_NAME: 'footer-googlePlayLogo',
        ALT_NAME: 'Google play',
        GRID: 'col-xl-3'
    }/* ,
    {
        LINKS: Href.DOWNLOAD_BUTTON_CHROME_EXTENCTION,
        IMAGE: Images.chrome,
        CLASS_NAME: 'footer-chromeWebStoreLogo',
        ALT_NAME: 'Chrome Web Store',
        GRID: 'col-xl-2'
    }, */
]


const socialMediaButtonsData = [
    {
        LINKS: Href.GOSATS_FACEBOOK,
        ICONS: Icons.FaFacebookF,
    },
    {
        LINKS: Href.GOSATS_LINKEDIN,
        ICONS: Icons.FaLinkedinIn,
    },
    {
        LINKS: Href.GOSATS_TWITTER,
        ICONS: Icons.FaTwitter,
    },
    {
        LINKS: Href.GOSATS_INSTAGRAM,
        ICONS: Icons.AiFillInstagram,
    },
    {
        LINKS: Href.GOSATS_MAILTO,
        ICONS: Icons.MdEmail,
    },
    {
        LINKS: Href.GOSATS_TELEGRAM,
        ICONS: Icons.FaTelegramPlane,
    }

]

const communityButtonsData = [
    {
        LINKS: Href.GOSATS_TELEGRAM,
        BUTTON_CLASS_NAME: 'comButtom',
        ICON_CLASS_NAME: 'comTel',
        ICONS: Icons.FaTelegramPlane,
        BUTTON_TITLE: Strings.GOSATS_COMMUNITY_TELEGRAM_TITLE
    },
    {
        LINKS: Href.GOSATS_WHATSAPP,
        BUTTON_CLASS_NAME: 'comButtomWhatsApp',
        ICON_CLASS_NAME: 'comWhatsApp',
        ICONS: Icons.BsWhatsapp,
        BUTTON_TITLE: Strings.GOSATS_COMMUNITY_WHATSAPP_TITLE
    },
    {
        LINKS: Href.GOSATS_TWITTER,
        BUTTON_CLASS_NAME: 'comButtomTweet',
        ICON_CLASS_NAME: 'comTweet',
        ICONS: Icons.FaTwitter,
        BUTTON_TITLE: Strings.GOSATS_COMMUNITY_Twitter_TITLE
    },
]
const Footer = (props) => {

    return (
        <div className='container footerSection'>
            <CommunitySection />
            <div className='footer-nav '>
                <div className='d-flex flex-wrap align-items-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 footer-sectionOne'>
                    <div className=' col-md-12 col-lg-12 col-xl-6 row d-flex  footer-sectionOne-nav'>
                        <div className='col-12 col-md-4 col-lg-3 col-xl-3 footer-sectionOne-logo'>
                            <NavLink to='/'>
                                <img src={Images.gosats} alt='GoSats' className='footer-logo' />
                            </NavLink>
                        </div>
                        <div className='col-12 col-md-8 col-xl-4 col-lg-7 col-xl-9 d-flex justify-content-between   footer-sectionOne-content'>
                            <div >
                                <NavLink to='/about' className='footer-links col-lg-3 col-xl-3'>About</NavLink>
                            </div>
                            <div >
                                <NavLink to='/card' className='footer-links col-lg-3 col-xl-3'>Card</NavLink>
                            </div>
                            <FooterNavLinks data={footerNavData} />
                        </div>
                    </div>
                    <FooterDownloadButtons data={footerDownloadButtonsData} />
                </div>
                <div className=' mt-4 divider'></div>
                <div className='col-lg-12 col-md-12  mt-4  d-flex flex-wrap justify-content-xl-between'>
                    <FooterSocialMediaButtons data={socialMediaButtonsData} />
                    <div className='col-12  col-xl-5 col-md-12 col-lg-6 mb-4 d-flex-column justify-content-xl-end justify-content-lg-end  copy-right'>
                        <p className='copy-right-title'>&copy; {Strings.COPYRIGHT_TITLE}</p>
                        <p className='gst-info'>{Strings.GOSATS_COMMUNITY_GST_INFO}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

const FooterNavLinks = ({ data }) => {
    return data.map((content, index) => (
        <div key={index}>
            <a className='footer-links col-lg-3 col-xl-4' href={content.LINKS}>{content.TITLE}</a>
        </div>
    ))
}

const FooterDownloadButtons = ({ data }) => {
    return (
        <div className="row d-flex col-md-6 col-lg-6 col-xl-6 justify-content-xl-end   justify-content-lg-end  justify-content-md-between  footer-downloadLinks">
            {
                data.map((data, index) => (
                    <div className=" col-6 col-md-4 col-lg-6 col-xl-3 footer-button-appStore " key={index}>
                        <a href={data.LINKS} target="_blank" rel="noreferrer">
                            <img src={data.IMAGE} className={data.CLASS_NAME} alt={data.ALT_NAME} style={{ width: 136, hight: 45 }} />
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

const FooterSocialMediaButtons = ({ data }) => {
    return (
        <div className='col-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-wrap  footer-media-container'>
            {
                data.map((data, index) => (
                    <div className='col-2 col-md-1 col-xl-1 col-lg-1 icon' key={index}>
                        <a href={data.LINKS} className='footer-Social-media'>
                            <data.ICONS color='#A6A7A8' className='footer-icon' />
                        </a>
                    </div>
                ))
            }
            <div className='col-2 col-md-1 col-xl-1 col-lg-1 koo'>
                <a href={Href.GOSATS_KOO} className='footer-Social-media'>
                    <img src={Images.koo} className='footer-icon' alt='koo icon' />
                </a>
            </div>
        </div>
    )
}

const CommunityButtons = ({ data }) => {
    return (
        <div className='comSocialmedia d-flex flex-wrap'>
            {
                data.map((data, index) => (
                    <div className='comButtonHolder' key={index}>
                        <a href={data.LINKS} target="_blank" rel="noreferrer">
                            <button className={data.BUTTON_CLASS_NAME}><data.ICONS className={data.ICON_CLASS_NAME} />{data.BUTTON_TITLE}</button>
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

const CommunitySection = () => {
    return (
        <div className='footerSubSection'>
            <div className='footer-community d-flex flex-wrap align-items-center align-self-center'>
                <div className='footerImg-holder'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-11 d-lg-none'>
                            <div className='comTitle'>{Strings.GOSATS_COMMUNITY_TITLE}</div>
                        </div>
                    </div>
                    <img src={Images.comImg} className='footerImgMob' alt='Social Media' />
                </div>
                <div className='footerContant-holder  col-lg-8'>
                    <div className='comTitle d-none d-lg-block'>{Strings.GOSATS_COMMUNITY_TITLE}</div>
                    <p className='comSubTitle  col-xl-7'>{Strings.GOSATS_COMMUNITY_SUBTITLE}</p>
                    <CommunityButtons data={communityButtonsData} />
                </div>
                <img src={Images.comImg} className='footerImg' alt='Social Media' />
            </div>
        </div>
    )
}

export default Footer;